/*
  Project: Barbara Brown
  Author: James Barr
 */

window.jQuery = window.$ = require('jquery');

var header = require('./header.js')
var nav = require('./nav.js')
var magic = require('./magic.js')
var form = require('./form.js')
var selectFocus = require('./select-focus.js')

header();
nav();
magic();
form();
selectFocus();
