'use strict';

var $ = require('jquery');

var nav = function () {
  var scrollEls = document.getElementsByClassName('js-scroll-to');
  var $html = $('html');
  var $htmlBody = $('html, body');
  var $scrollEls = $(scrollEls)
  var $headerEl = $('.js-fixed-header');
  var navToggleEl = document.querySelector('.js-nav-toggle');
  var mediaQueryString = '(min-width: 768px)'
  var MOBILE_NAV_HEIGHT = 0;
  var DESKTOP_NAV_HEIGHT = 0;
  var DURATION_MOBILE = 0;
  var DURATION_DESKTOP = 750;

  $scrollEls.each(function() {
    this.addEventListener('click', scrollClickEvent)
  });

  function getNavHeight() {
    if (matchMedia(mediaQueryString).matches) {
      return DESKTOP_NAV_HEIGHT;
    } else {
      return MOBILE_NAV_HEIGHT;
    }
  }

  function getDuration() {
    if (matchMedia(mediaQueryString).matches) {
      return DURATION_DESKTOP;
    } else {
      return DURATION_MOBILE;
    }
  }

  navToggleEl.addEventListener('click', function(e) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (this.checked) {
      $html.addClass('nav-open');
    } else {
      $html.removeClass('nav-open');
    }
  });

  function scrollClickEvent(e) {
    var targetSelector = this.getAttribute('href');
    var targetEl = document.querySelector(targetSelector);
    var $target = $(targetEl);
    var currentNavHeight = getNavHeight();
    var animationDuration = getDuration();

    e.preventDefault();

    navToggleEl.checked = false;

    $scrollEls.removeClass('is-active');
    $html.removeClass('nav-open');
    $target.addClass('is-active');

    if (Math.round($(document).scrollTop()) != $target.offset().top - currentNavHeight) {
      $htmlBody.animate({
        scrollTop: $target.offset().top - currentNavHeight
      }, animationDuration, unPinHeader);
    }
  }

  function unPinHeader() {
    var timeoutID = window.setTimeout(function() {
      $headerEl.removeClass('is-pinned');
      $headerEl.addClass('is-unpinned');
    }, 50);
  }
};

module.exports = nav;
