'use strict';

var $ = require('jquery');
var ScrollMagic = require("scrollmagic");

var magic = function () {
  var $introPanel = $('.c-panel--intro');
  var $fadeUps = $('.u-fade-up');
  var $headerEls = $('.js-fixed-logo, .js-fixed-header');
  var $window = $(window);
  var HALF_DIVISOR = 2;
  var HALF_INTRO_PANEL_HEIGHT = $introPanel.height() / HALF_DIVISOR;
  var LOGO_HEIGHT = $('.c-site-logo').outerHeight();
  var TOP_TRIGGER_HOOK = 0;
  var BOTTOM_TRIGGER_HOOK = 1;
  var mediaQueryString = '(min-width: 768px)';
  var fadeUpsInitialised = false;
  var destroyWindowResizeEvent = false;

  $introPanel.next().attr('id', 'secondPanel');

  $('html').addClass('page-loaded');

  // Init controller
  var controller = new ScrollMagic.Controller();

  var setHeaderFixed = new ScrollMagic.Scene({
    offset: HALF_INTRO_PANEL_HEIGHT })
    .setClassToggle('.c-header-wrapper', "is-fixed")
    .triggerHook(TOP_TRIGGER_HOOK)
    .addTo(controller);

  $headerEls.each(function() {
    var $thisEl = $(this);

    var setFixed = new ScrollMagic.Scene({
      offset: LOGO_HEIGHT })
      .setClassToggle(this, "can-be-shown")
      .triggerHook(TOP_TRIGGER_HOOK)
      .addTo(controller);

    // Headroom JS will handle pinning & unpinning of menu.
    // Logo will be revealed using the 'is-shown' class.
    if ($thisEl.hasClass('c-site-logo')) {
      var setShown = new ScrollMagic.Scene({
        triggerElement: "#secondPanel"})
        .setClassToggle(this, "is-shown")
        .triggerHook(TOP_TRIGGER_HOOK)
        .addTo(controller);
    }
  });

  var checkWindowSize = function() {
    if (matchMedia(mediaQueryString).matches) {
      setupFadeUpElements();
    }
  };

  var setupFadeUpElements = function() {
    if (fadeUpsInitialised == true) {
      if (destroyWindowResizeEvent == false) {
        $window.off('resize');
        destroyWindowResizeEvent = true;
      }

      return;
    }

    fadeUpsInitialised = true;

    $fadeUps.each(function() {
      var scene = new ScrollMagic.Scene({
        triggerElement: this})
          .setClassToggle(this, "in-view")
          .triggerHook(BOTTOM_TRIGGER_HOOK)
          .addTo(controller);
    });
  };

  var checkWindowSizeOnResize = function() {
    if (fadeUpsInitialised == true) {
      return;
    }
    
    $window.on('resize', function() {
      checkWindowSize();
    });
  };

  checkWindowSize();
  checkWindowSizeOnResize();
};

module.exports = magic
