'use strict';

var $ = require('jquery');
var parsley = require('parsleyjs');

var form = function () {
  var $form = $('.js-ajax-submit');
  var $formMessages = $('.c-form-messages');
  var confirmationHeader = '<div class="c-confirmation"><h4 class="c-confirmation__heading">Message Sent</h4>';
  var confirmationEnquiry = '<p class="c-confirmation__message">Thank you for submitting your enquiry. We will be in contact with you shortly.</p>';
  var confirmationSignedUp = '<p class="c-confirmation__message">You have been signed up to our monthly newsletter.</p>';
  var confirmationMessages = '';
  var confirmationFooter = '</div>';
  var errorHeader = '<div class="c-errors"><h4 class="c-errors__heading">Please correct the following errors</h4><ul class="c-errors__list">';
  var errorMessages = '';
  var errorFooter = '</ul></div>';
  var $btnLoader = $('.c-btn--loader');
  var $fieldsThatSync = $('[data-sync-with]');
  var $signUp = $('.js-sign-up');
  var enquirySumitted = false;
  var signedUp = false;

  $form.parsley();

  $fieldsThatSync.each(function() {
    var $this = $(this);
    var fieldToSyncWithSelector = $this.data('sync-with');
    var $fieldToSyncWith = $(fieldToSyncWithSelector);

    $this.on('input', function() {
      $fieldToSyncWith.val(this.value);
    });
  });

  $form.on('submit', function(e) {
    var signTheUserUp = $signUp.is(':checked');
    var formData = $form.serialize();

    errorMessages = '';
    confirmationMessages = '';

    e.preventDefault();

    if (signTheUserUp) {
      $.ajax({
        url : 'https://thevillageofuseful.createsend.com/t/r/s/urtydyy/',
        method: 'POST',
        data: formData,
        dataType: 'jsonp',
        success: function(data){
          if (data.Status === 400) {
            renderAnError(data.Message);
          } else {
            signedUp = true;
            renderConfirmation();
          }
          $btnLoader.removeClass('is-loading');
        },
        error: function(){
          $btnLoader.removeClass('is-loading');
          renderAnError('The server experienced an issue. Please try again later.');
        }
      });
    }

    $btnLoader.addClass('is-loading');

    $.ajax({
      url : '',
      method: 'POST',
      data: formData,
      success: function(response){
        if (response.ok == "yes") {
          enquirySumitted = true;
          renderConfirmation();
        } else if (response.ok == "no") {
          renderErrors(response);
        }
        $btnLoader.removeClass('is-loading');
      },
      error: function(){
        $btnLoader.removeClass('is-loading');
        renderAnError('The server experienced an issue. Please try again later.');
      }
    });

    return false;
  });

  var renderConfirmation = function() {
    if (enquirySumitted) {;
      confirmationMessages = confirmationEnquiry;;
    }

    if (signedUp) {
      confirmationMessages += confirmationSignedUp;
    }

    $formMessages.html(confirmationHeader + confirmationMessages + confirmationFooter);
  }

  var renderErrors = function(response) {
    var errors = response.errors;

    for (var property in errors) {
      if (errors.hasOwnProperty(property)) {
        errorMessages += '<li>' + errors[property] + '</li>'
      }
    }

    $formMessages.html(errorHeader + errorMessages + errorFooter);
  }

  var renderAnError = function(message) {
    var errorMessage = '<li>' + message + '</li>'

    $formMessages.html(errorHeader + errorMessage + errorFooter);
  }
};

module.exports = form;
